<template>
  <div class="c">
    <el-tabs v-model="activeName">
      <el-tab-pane label="通过原密码方式" name="1">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
          "
        >
          <el-form ref="form" :model="obj" :rules="rules" label-width="140px">
            <el-form-item label="原密码:" prop="oldPassword">
              <el-input
                :name="Math.random() * 10000"
                autocomplete="off"
                placeholder="请输入"
                style="width: 370px"
                v-model="obj.oldPassword"
                clearable
                :disabled="!isEdit"
              />
            </el-form-item>

            <el-form-item label="新密码:" prop="password">
              <el-input
                :name="Math.random() * 10000"
                autocomplete="off"
                placeholder="请输入"
                v-model="obj.password"
                clearable
                :disabled="!isEdit"
              />
            </el-form-item>

            <el-form-item label="确认密码:" prop="confirmPassword">
              <el-input
                :name="Math.random() * 10000"
                autocomplete="off"
                placeholder="请输入"
                v-model="obj.confirmPassword"
                clearable
                :disabled="!isEdit"
              />
            </el-form-item>
          </el-form>
        </div>
        <div style="text-align: center">
          <el-button size="mini" style="flex: 1" type="primary" @click="confirm"
            >保存</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="通过手机验证码方式" name="2">
        <forget-password notShowBack @changePage="() => {}" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ElMessage } from "element-plus";
import forgetPassword from "../../loginModule/components/forgetPassword.vue"; //忘记密码

export default {
  data() {
    return {
      isEdit: true, //是否可编辑
      activeName: "1",
      obj: {
        oldPassword: "", //原密码
        password: "", //新密码
        confirmPassword: "", //确认密码
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请确认新密码", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    forgetPassword,
  },
  methods: {
    ...mapActions("systemModule", ["userPassword"]),
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.obj.confirmPassword !== this.obj.password) {
            return ElMessage({
              type: "warning",
              message: "两次输入的密码不一致",
            });
          }
          var data = JSON.parse(JSON.stringify(this.obj));
          delete data.confirmPassword;
          this.userPassword(data).then((res) => {
            let { code, data } = res.data;
            if (code === "0") {
              ElMessage({
                type: "success",
                message: "修改成功",
              });
              //清空输入的值
              this.obj = {
                oldPassword: "", //原密码
                password: "", //新密码
                confirmPassword: "", //确认密码
              };
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "../style/index.less";
</style>
